export function getBgColorFromDate(dateString) {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const providedDate = new Date(dateString).setHours(0, 0, 0, 0);
  console.log(providedDate, currentDate);
  // Comparamos las fechas
  if (providedDate < currentDate) {
    // Fecha anterior a hoy
    return "linear-gradient(90deg, rgba(239,68,68,1) 0%, rgba(220,38,38,1) 100%)";
  } else if (
    providedDate === currentDate
  ) {
    // Fecha de hoy y mañana
    return "linear-gradient(90deg, rgba(217,119,6,1) 0%, rgba(245,158,11,1) 100%)";
  } else {
    // Fecha después de la siguiente semana
    return "linear-gradient(90deg, rgba(13,148,136,1) 0%, rgba(20,184,166,1) 100%)";
  }
}

