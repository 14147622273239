/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Menu, Transition } from "@headlessui/react";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import ListBoxComponent from "../../utils/ListBoxComponent";
import Loader from "../../utils/Loader";
import { FIRMFLOW_NAMES } from "../../helpers/firmFlowNames";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const emptyDate = "0001-01-01T00:00:00";

export default function UpdateTaskModal({
  open,
  setOpen,
  form,
  setForm,
  updateTask,
  employees,
  setEmployees,
  loggedInEmployee,
}) {
  const cancelButtonRef = useRef(null);
  const [showRenewal, setShowRenewal] = useState(false);
  const status_map = ["In progress", "Blocked", "Complete"];
  const [errors, setErrors] = useState([]);
  const [selectedAssignToEmployees, setSelectedAssignToEmployees] = useState(
    form.assignedEmployees
  );
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setForm({ ...form, assignedEmployees: selectedAssignToEmployees });
    console.log("form in update task modal", form);
  }, [selectedAssignToEmployees]);

  //When status is complete, completedDate is current date, none otherwise.
  useEffect(() => {
    if (form.status == 2) {
      setShowRenewal(true);
      if (loggedInEmployee) {
        let employee = employees.find(
          (_) => _.employeeId === loggedInEmployee.employeeId
        );
        setForm({
          ...form,
          completedBy: {
            employeeId: employee.employeeId,
            email: employee.email,
            firmId: employee.firmId,
            firstName: employee.firstName,
            lastName: employee.lastName,
            userId: employee.userId,
          },
        });
      }
      setForm({
        ...form,
        completedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
    } else {
      setForm({
        ...form,
        completedDate: emptyDate,
      });
      setShowRenewal(false);
    }
  }, [form.status]);

  const handleOnSave = async () => {
    setLoader(true);
    const formIsValid = await validateForm();
    if (formIsValid) {
      updateTask();
    }
    setLoader(false);
  };

  const handleOnChangeStatus = (statusIndex) => {
    setForm({ ...form, status: statusIndex });
  };

  async function validateForm() {
    return new Promise((resolve) => {
      console.log(form);

      if (form.status == 2) {
        var empty_fields = {};
        Object.keys(form).forEach(function (key) {
          if (
            form[key] == "" ||
            form[key] == null ||
            typeof form[key] == "undefined" ||
            form[key] == emptyDate
          ) {
            if (
              key != "search" &&
              key != "taskRenewalState" &&
              key != "notes" &&
              key != "completedDate" &&
              key != "clientName" &&
              key != "clientServiceSubscriptionServiceName"
            ) {
              empty_fields[key] = "This field cannot be empty.";
            }
          }
        });
        console.log("empty_fields", empty_fields);
        if (Object.keys(empty_fields).length > 0) {
          console.log(empty_fields);
          setErrors(empty_fields);
          console.log("return false");
          resolve(false);
        } else {
          resolve(true);
        }
      } else {
        console.log("return true");
        resolve(true);
      }
    });
  }

  useEffect(() => {
    console.log("form ", form.assignedEmployees);
    if (!open) {
      setTimeout(() => {
        setErrors({});
      }, 200);
    } else {
      /**
       * When modal opens, assignedByEmployee and assignedToEmployee equals LoggedInEmployee
       * completedDate equals current date
       * */
      setForm({
        ...form,
        assignedByEmployee: loggedInEmployee
          ? loggedInEmployee
          : form.assignedByEmployee,
        assignedToEmployee: loggedInEmployee
          ? loggedInEmployee
          : form.assignedToEmployee,
        completedBy: loggedInEmployee ? loggedInEmployee : form.completedBy,
      });
      setSelectedAssignToEmployees([]);
    }
  }, [open]);

  useEffect(() => {
    if (form.search === "") {
      const arr = [...employees];
      // eslint-disable-next-line
      arr.map((service) => {
        service.is_showing = true;
      });
      setEmployees(arr);
    }

    const arr = [...employees];
    // eslint-disable-next-line
    arr.map((employee) => {
      if (
        employee.firstName
          .toLowerCase()
          .includes(
            typeof form.search != "undefined"
              ? form.search.toLocaleLowerCase()
              : ""
          ) ||
        employee.lastName
          .toLowerCase()
          .includes(
            typeof form.search != "undefined"
              ? form.search.toLocaleLowerCase()
              : ""
          )
      ) {
        employee.is_showing = true;
      } else {
        employee.is_showing = false;
      }
    });
    setEmployees(arr);
    // eslint-disable-next-line
  }, [form.search]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <div className="text-center">
                  <div className="w-full flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-left w-full text-lg leading-6 font-medium text-gray-900"
                    >
                      Update {FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK}
                    </Dialog.Title>
                    <button onClick={() => setOpen(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Task name */}
                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="name"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="relative inline-block text-left w-full">
                      <input
                        onChange={(e) =>
                          setForm({
                            ...form,
                            name: e.target.value,
                          })
                        }
                        type="text"
                        value={form.name}
                        className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                      />
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="email"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <div>
                        <Menu.Button className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                          {status_map[form.status]}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4 mt-0.5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1 max-h-72 overflow-auto w-full">
                            {
                              // eslint-disable-next-line
                              status_map.map((status, statusIndex) => {
                                return (
                                  <Menu.Item key={statusIndex}>
                                    {({ active }) => (
                                      <button
                                        onClick={() => {
                                          handleOnChangeStatus(statusIndex);
                                        }}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer w-full text-left"
                                        )}
                                      >
                                        {status_map[statusIndex]}
                                      </button>
                                    )}
                                  </Menu.Item>
                                );
                              })
                            }
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  {showRenewal && (
                    <div className="mt-5 sm:mt-6">
                      <label
                        htmlFor="renewal"
                        className="text-left block text-sm font-medium text-gray-700"
                      >
                        <input
                          onChange={(e) =>
                            setForm({
                              ...form,
                              setTaskRenewalState: e.target.checked,
                            })
                          }
                          type="checkbox"
                          id="renew"
                          name="renew-task"
                          className="form-chexkbox"
                        ></input>
                        <span className="text-sm font-medium ml-2">
                          Check this box to enable the "Renewal" button after
                          saving.
                        </span>
                      </label>
                    </div>
                  )}

                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="name"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Notes
                    </label>
                    <div className="relative inline-block text-left w-full">
                      <textarea
                        onChange={(e) =>
                          setForm({
                            ...form,
                            notes: e.target.value,
                          })
                        }
                        type="text"
                        value={form.notes ? form.notes : ""}
                        className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                      />
                    </div>
                  </div>

                  {/* Testing adding multiple employees to task */}
                  <ListBoxComponent
                    title="Assigned to Employees"
                    selectedData={selectedAssignToEmployees}
                    setSelectedData={setSelectedAssignToEmployees}
                    fieldsToShow={["firstName", "lastName"]}
                    data={employees}
                    dataId={employees.employeeId}
                    multiple
                  />
                  {typeof errors.assignedEmployees != "undefined" && (
                    <p className="text-left text-red-500 text-sm mt-1">
                      {errors.assignedEmployees}
                    </p>
                  )}
                  {/* End of testing adding multiple employees to task */}

                  {/*
                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="email"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Assigned to
                    </label>
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <div>
                        <Menu.Button
                          onClick={() => {
                            setForm({ ...form, search: "" });
                          }}
                          className="inline-flex w-full truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                        >
                          {form.assignedToEmployee == false ||
                          typeof form.assignedToEmployee == "undefined" ||
                          form.assignedToEmployee == null
                            ? "Select employee"
                            : form.assignedToEmployee.firstName +
                              " " +
                              form.assignedToEmployee.lastName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4 mt-0.5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="p-3 pb-1 w-full">
                            <input
                              onChange={(e) =>
                                setForm({ ...form, search: e.target.value })
                              }
                              type="text"
                              value={form.search}
                              placeholder="Search.."
                              className="shadow-sm border border-slate-200 rounded-md p-1 px-2 w-full focus:ring-0 focus:outline-none"
                            />
                          </div>

                          <div className="py-1 max-h-72 overflow-auto w-full">
                            {
                              // eslint-disable-next-line
                              employees.map((employee, i) => {
                                if (!employee.is_showing) return;
                                return (
                                  <Menu.Item key={i}>
                                    {({ active }) => (
                                      <button
                                        onClick={() => {
                                          if (
                                            typeof errors.assignedToEmployee !=
                                            "undefined"
                                          ) {
                                            const _errors = { ...errors };
                                            delete _errors.assignedToEmployee;
                                            setErrors(_errors);
                                          }

                                          setForm({
                                            ...form,
                                            assignedToEmployee: employee,
                                          });
                                        }}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer w-full text-left"
                                        )}
                                      >
                                        {employee.firstName +
                                          " " +
                                          employee.lastName}
                                      </button>
                                    )}
                                  </Menu.Item>
                                );
                              })
                            }
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <small className="relative inline-block text-left w-full">
                      Current user assigned by default.
                    </small>
                  </div>

                  {typeof errors.assignedToEmployee != "undefined" && (
                    <p className="text-left text-red-500 text-sm mt-1">
                      {errors.assignedToEmployee}
                    </p>
                  )} */}

                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="email"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Assigned by
                    </label>
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <div>
                        <Menu.Button
                          onClick={() => {
                            setForm({ ...form, search: "" });
                          }}
                          className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                        >
                          {form.assignedByEmployee == false ||
                          typeof form.assignedByEmployee == "undefined" ||
                          form.assignedByEmployee == null
                            ? "Select employee"
                            : form.assignedByEmployee.firstName +
                              " " +
                              form.assignedByEmployee.lastName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4 mt-0.5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="w-full absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="p-3 pb-1">
                            <input
                              onChange={(e) =>
                                setForm({ ...form, search: e.target.value })
                              }
                              type="text"
                              value={form.search}
                              placeholder="Search.."
                              className="shadow-sm border border-slate-200 rounded-md p-1 px-2 w-full focus:ring-0 focus:outline-none"
                            />
                          </div>

                          <div className="py-1 max-h-72 overflow-auto w-full">
                            {
                              // eslint-disable-next-line
                              employees &&
                                employees.map((employee, i) => {
                                  if (!employee.is_showing) return;
                                  return (
                                    <Menu.Item key={i}>
                                      {({ active }) => (
                                        <a
                                          onClick={() => {
                                            if (
                                              typeof errors.assignedByEmployee !=
                                              "undefined"
                                            ) {
                                              const _errors = { ...errors };
                                              delete _errors.assignedByEmployee;
                                              setErrors(_errors);
                                            }

                                            setForm({
                                              ...form,
                                              assignedByEmployee: employee,
                                            });
                                          }}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm cursor-pointer w-full text-left"
                                          )}
                                        >
                                          {employee.firstName +
                                            " " +
                                            employee.lastName}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  );
                                })
                            }
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <small className="relative inline-block text-left w-full">
                      Current user assigned by default.
                    </small>
                  </div>

                  {typeof errors.assignedByEmployee != "undefined" && (
                    <p className="text-left text-red-500 text-sm mt-1">
                      {errors.assignedByEmployee}
                    </p>
                  )}

                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="email"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Completed by
                    </label>
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <div>
                        <Menu.Button
                          onClick={() => {
                            setForm({ ...form, search: "" });
                          }}
                          className="inline-flex w-full truncate justify-between rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                        >
                          {form.completedBy == false ||
                          typeof form.completedBy == "undefined" ||
                          form.completedBy == null
                            ? "Select employee"
                            : form.completedBy.firstName +
                              " " +
                              form.completedBy.lastName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4 mt-0.5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="w-full absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="p-3 pb-1">
                            <input
                              onChange={(e) => {
                                setForm({ ...form, search: e.target.value });
                              }}
                              type="text"
                              value={form.search}
                              placeholder="Search.."
                              className="shadow-sm border border-slate-200 rounded-md p-1 px-2 w-full focus:ring-0 focus:outline-none"
                            />
                          </div>

                          <div className="py-1 max-h-72 overflow-auto w-full">
                            {
                              // eslint-disable-next-line
                              employees.map((employee, i) => {
                                if (!employee.is_showing) return;
                                return (
                                  <Menu.Item key={i}>
                                    {({ active }) => (
                                      <a
                                        onClick={() => {
                                          if (
                                            typeof errors.completedBy !=
                                            "undefined"
                                          ) {
                                            const _errors = { ...errors };
                                            delete _errors.completedBy;
                                            setErrors(_errors);
                                          }

                                          setForm({
                                            ...form,
                                            completedBy: employee,
                                          });
                                        }}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer w-full text-left"
                                        )}
                                      >
                                        {employee.firstName +
                                          " " +
                                          employee.lastName}
                                      </a>
                                    )}
                                  </Menu.Item>
                                );
                              })
                            }
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {typeof errors.completedBy != "undefined" && (
                    <p className="text-left text-red-500 text-sm mt-1">
                      {errors.completedBy}
                    </p>
                  )}

                  <div className="flex flex-col mt-5 sm:mt-6">
                    <h1 className="text-left block text-sm font-medium text-gray-700">
                      Assigned Date
                    </h1>
                    <input
                      defaultValue={
                        form.assignedDate != null
                          ? form.assignedDate.split("T")[0]
                          : ""
                      }
                      onChange={(e) => {
                        if (
                          e.target.value != emptyDate &&
                          typeof errors.assignedDate != "undefined"
                        ) {
                          const _errors = { ...errors };
                          delete _errors.assignedDate;
                          setErrors(_errors);
                        }

                        setForm({ ...form, assignedDate: e.target.value });
                      }}
                      type="date"
                      id="start"
                      name="trip-start"
                      className="form-select pr-1"
                    ></input>
                  </div>

                  {typeof errors.assignedDate != "undefined" && (
                    <p className="text-left text-red-500 text-sm mt-1">
                      {errors.assignedDate}
                    </p>
                  )}

                  <div className="flex flex-col mt-5 sm:mt-6">
                    <h1 className="text-left block text-sm font-medium text-gray-700">
                      Completed Date
                    </h1>

                    <input
                      value={
                        form.completedDate !== undefined &&
                        form.completedDate !== null &&
                        form.completedDate.split("T")[0]
                      }
                      onChange={(e) => {
                        if (
                          e.target.value != emptyDate &&
                          typeof errors.completedDate != "undefined"
                        ) {
                          const _errors = { ...errors };
                          delete _errors.completedDate;
                          setErrors(_errors);
                        }
                        setForm({ ...form, completedDate: e.target.value });
                      }}
                      type="date"
                      id="start"
                      name="trip-start"
                      className="form-select pr-1"
                    />

                    <small className="relative inline-block text-left w-full">
                      Date assigned automatically.
                    </small>
                  </div>

                  <div className="flex flex-col mt-5 sm:mt-6">
                    <h1 className="text-left block text-sm font-medium text-gray-700">
                      Due Date
                    </h1>
                    <input
                      defaultValue={
                        form.dueDate != null ? form.dueDate.split("T")[0] : ""
                      }
                      onChange={(e) => {
                        if (
                          e.target.value != emptyDate &&
                          typeof errors.dueDate != "undefined"
                        ) {
                          const _errors = { ...errors };
                          delete _errors.dueDate;
                          setErrors(_errors);
                        }

                        setForm({ ...form, dueDate: e.target.value });
                      }}
                      type="date"
                      id="start"
                      name="trip-start"
                      className="form-select pr-1"
                    ></input>
                  </div>
                  {typeof errors.dueDate != "undefined" && (
                    <p className="text-left text-red-500 text-sm mt-1">
                      {errors.dueDate}
                    </p>
                  )}
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    disabled={loader}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                    onClick={() => {
                      handleOnSave();
                    }}
                  >
                    {!loader ? "Save" : <Loader />}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
