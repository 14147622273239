import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { createTaskV2 } from "../../services/serviceTasks/createTaskV2";
import { getNewLoggedInEmployeeFunction } from "../../helpers/getLoggedInEmployeeFunction";
import toast, { Toaster } from "react-hot-toast";

function CreateTask() {
    const [loggedInEmployee, setLoggedInEmployee] = useState();
    const navigate = useNavigate();
    const params = useParams();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [form, setForm] = useState({
        id: params.jobId,
        name: "",
    });

    async function create() {
        if (loggedInEmployee.role === 0) {
            toast.error("You don't have permission to do this.");
            return;
        }

        if (form.name) {
            const task = await createTaskV2(form);
            console.log(task);
            alert(task.data.message);
            if (task.data.success) {
                navigate(`/templates/job/${params.jobId}`);
            }
        } else {
            alert("Name can't be empty!");
        }
    }

    useEffect(() => {
        getNewLoggedInEmployeeFunction(setLoggedInEmployee);
    }, []);

    return (
        <section>
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <Breadcrumbs
                                    home="/templates/jobs"
                                    pages={[
                                        {
                                            name: "Job template setup",
                                            href: `/templates/job/${params.jobId}`,
                                            current: true,
                                        },
                                        {
                                            name: "Create job",
                                            href: `/templates/jobs/${params.jobId}/task/create`,
                                            current: true,
                                        },
                                    ]}
                                />

                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                            Create Job
                                        </h1>
                                    </div>
                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Details
                                        </h2>
                                    </header>

                                    <div className="space-y-5 px-5 py-4">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            name: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form.name}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={() => create()}
                                        type="submit"
                                        className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default CreateTask;
