import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import CreateDepartmentForm from "./components/deparments/CreateDepartmentForm";
import EditDepartmentForm from "./components/deparments/EditDepartmentForm";
import Clients from "./pages/Clients/Clients";
import CreateClientForm from "./pages/Clients/CreateClientForm";
import EditClientForm from "./pages/Clients/EditClientForm";
import Accountability from "./pages/Dashboards/Accountability/Accountability";
import ClientInsights from "./pages/Dashboards/ClientInsights";
import Dashboard from "./pages/Dashboards/Dashboard";
import DueDateReport from "./pages/Dashboards/DueDateReport";
import EfileStatusReport from "./pages/Dashboards/EfileStatusReport";
import JobsReport from "./pages/Dashboards/JobsReport";
import JobsReportV2 from "./pages/Dashboards/JobsReportV2";
import ServiceInsights from "./pages/Dashboards/ServiceInsights";
import TaxAgendaReport from "./pages/Dashboards/TaxAgendaReport";
import CreateEmployee from "./pages/Employees/CreateEmployee";
import EditEmployeeForm from "./pages/Employees/EditEmployee";
import EmployeesPage from "./pages/Employees/EmployeesPage";
import ForgotPassword from "./pages/ForgotPassword/Index";
import CreateJob from "./pages/Jobs/CreateJob";
import CreateTask from "./pages/Jobs/CreateTask";
import EditJob from "./pages/Jobs/EditJob";
import EditTask from "./pages/Jobs/EditTask";
import JobsList from "./pages/Jobs/JobsList";
import Login from "./pages/Login/login";
import Register from "./pages/Login/register";
import ClientService from "./pages/ManageClients/ClientService";
import ClientSubscriptions from "./pages/ManageClients/ClientSubscriptions";
import ManageClients from "./pages/ManageClients/ManageClients";
import ResetPassword from "./pages/ResetPassword/Index";
import EditServiceForm from "./pages/ServiceForm/EditServiceForm";
import ServiceDepartments from "./pages/Services/ServiceDepartments";
import Services from "./pages/Services/Services";
import ServicesByDepartment from "./pages/Services/ServicesByDepartment";
import Settings from "./pages/Settings/Settings";
import CreateStateForm from "./pages/StateForm/CreateStateForm";
import CreateNewServiceWithJobs from "./pages/TaskForm/CreateNewServiceWithJobs";
import CreateTaskForm from "./pages/TaskForm/CreateTaskForm";
import EditTaskForm from "./pages/TaskForm/EditTaskForm";
import Tasks from "./pages/Tasks/Tasks";
import StatusReport from "./pages/Dashboards/StatusReport/StatusReport";

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!Cookies.get("Bearer")) {
            if (
                location.pathname !== "/" &&
                location.pathname !== "/register" &&
                location.pathname !== "/forgotPassword" &&
                !location.pathname.includes("resetPassword")
            ) {
                navigate("/");
            }
        } else {
            //console.log(Cookies.get('Bearer'));
            if (
                location.pathname === "/" ||
                location.pathname === "/register" ||
                location.pathname === "/forgotPassword" ||
                location.pathname.includes("resetPassword")
            ) {
                navigate("/tasks");
            }
        }

        document.querySelector("html").style.scrollBehavior = "auto";
        window.scroll({ top: 0 });
        document.querySelector("html").style.scrollBehavior = "";
        // eslint-disable-next-line
    }, [location.pathname]); // triggered on route change

    return (
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
                exact
                path="/clients/service/:clientServiceId/client/:clientId"
                element={<ClientService />}
            />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/services/:id" element={<EditServiceForm />} />
            <Route exact path="/services/old/:id" element={<EditServiceForm />} />
            <Route
                exact
                path="/services/:id/task/create"
                element={<CreateTaskForm />}
            />
            {/*   <Route
        exact
        path="/category/:id/job/create"
        element={<CreateJobForm />}
      /> */}
            <Route
                exact
                path="/services/:id/task/:task_id"
                element={<EditTaskForm />}
            />
            <Route exact path="/clients" element={<Clients />} />
            <Route exact path="/clients/create" element={<CreateClientForm />} />
            <Route exact path="/clients/manage" element={<ManageClients />} />
            <Route
                exact
                path="/clients/manage/:client_id"
                element={<ManageClients />}
            />
            <Route
                exact
                path="/clients/manage/:client_id/subscription/:id"
                element={<ClientSubscriptions />}
            />
            <Route exact path="/clients/:id" element={<EditClientForm />} />
            <Route exact path="/employees" element={<EmployeesPage />} />
            <Route exact path="/employees/:id" element={<EditEmployeeForm />} />
            <Route exact path="/employees/create" element={<CreateEmployee />} />
            <Route
                exact
                path="/services/:id/state/create"
                element={<CreateStateForm />}
            />
            <Route exact path="/forgotPassword" element={<ForgotPassword />} />
            <Route exact path="/resetPassword/:id" element={<ResetPassword />} />
            <Route
                exact
                path="/dashboards/tax-agenda-report"
                element={<TaxAgendaReport />}
            />
            <Route exact path="/dashboards/jobs-report" element={<JobsReport />} />
            <Route
                exact
                path="/dashboards/jobs-report-v2"
                element={<JobsReportV2 />}
            />
            <Route
                exact
                path="/dashboards/client-insights"
                element={<ClientInsights />}
            />
            <Route
                exact
                path="/dashboards/service-insights"
                element={<ServiceInsights />}
            />
            <Route
                exact
                path="/dashboards/accountability"
                element={<Accountability />}
            />
            <Route
                exact
                path="/dashboards/efile-status-report"
                element={<EfileStatusReport />}
            />
            <Route
                exact
                path="/dashboards/due-date-report"
                element={<DueDateReport />}
            />
            <Route
                exact
                path="/dashboards/status-report"
                element={<StatusReport />}
            />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/tasks" element={<Tasks />} />
            <Route
                exact
                path="/departments/create"
                element={<CreateDepartmentForm />}
            />
            <Route exact path="/departments/:id" element={<EditDepartmentForm />} />
            {/* <Route exact path="/categories" element={<Categories />} />
      <Route exact path="/categories/create" element={<CreateCategory />} />
      <Route exact path="/category/:id" element={<EditCategory />} />
      <Route
        exact
        path="/category/:categoryId/job/:jobId"
        element={<EditJobForm />}
      />
      <Route exact path="/templates/categories" element={<Categories />} /> */}
            {/* <Route exact path="/templates/services" element={<Services />} /> */}
            <Route exact path="/departments" element={<ServiceDepartments />} />
            <Route
                exact
                path="/templates/servicesByDepartment/:name"
                element={<ServicesByDepartment />}
            />
            <Route
                exact
                path="/templates/services/create"
                element={<CreateNewServiceWithJobs />}
            />
            <Route exact path="/templates/jobs" element={<JobsList />} />
            <Route exact path="/templates/jobs/create" element={<CreateJob />} />
            <Route exact path="/templates/job/:jobId" element={<EditJob />} />
            <Route
                exact
                path="/templates/jobs/:jobId/task/:taskId"
                element={<EditTask />}
            />
            <Route
                exact
                path="/templates/jobs/:jobId/tasks/create"
                element={<CreateTask />}
            />
        </Routes>
    );
}
export default App;
