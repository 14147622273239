import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
//import { getJobs } from "../../services/jobs/getJobs";
import { getServiceDepartments } from "../../services/service/getServiceDepartments";
import { getServiceV2 } from "../../services/service/getServiceV2";
import { getServiceTasksByJob } from "../../services/serviceTasks/getServiceTasksByJob";
import { updateService } from "../../services/updateService";
import DepartmentListbox from "../TaskForm/DepartmentListBox";
//import JobsListBox from "../TaskForm/JobsListBox";
import CheckboxForServices from "./CheckboxForServices";
import { getNewLoggedInEmployeeFunction } from "../../helpers/getLoggedInEmployeeFunction";

function EditServiceForm() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const serviceId = useParams().id;
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  //const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({ name: null });
  const [tasks, setTasks] = useState([]);
  const [loggedInEmployee, setLoggedInEmployee] = useState(null);

  const [service, setService] = useState({
    name: "",
  });
  const [form, setForm] = useState({
    name: "",
    department: null,
    isEfileStatusActive: false,
    isEveryItemRenewable: false,
  });

  const getNewDepartments = async () => {
    const response = await getServiceDepartments();
    if (response.success) {
      setDepartments(response.data.departments);
      setSelectedDepartment(response.data.departments[0]);
    } else {
      toast.error(response.data.message);
    }
  };

  /*  const getNewJobs = async () => {
    const response = await getJobs();
    if (response.success) {
      console.log(response.data.jobs);
      setJobs(response.data.jobs);

    } else {
      toast.error(response.data.message);
    }
  }; */

  const getNewTasks = async (jobId) => {
    const response = await getServiceTasksByJob(jobId);
    if (response.success) {
      setTasks(response.data);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    async function runOnLoad() {
      if (typeof serviceId === "undefined") return;

      const service = await getServiceV2(serviceId);
      if (service.success === false) return navigate("/departments");
      console.log(service);
      setForm({
        name: service.data.name,
        isEfileStatusActive: service.data.isEfileStatusActive,
        isEveryItemRenewable: service.data.isEveryItemRenewable,
      });

      setService({
        name: service.data.name,
      });

      setSelectedDepartment(service.data.department);
      setSelectedJob(service.data.job);
      getNewTasks(service.data.job.id);
      setLoading(false);
    }
    getNewDepartments();
    //getNewJobs();
    runOnLoad();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getNewLoggedInEmployeeFunction(setLoggedInEmployee);
  }, []);

  const saveDetails = async () => {
    if (loggedInEmployee.role === 0) {
      toast.error("You don't have permission to do this.");
      return;
    }

    if (form.name == "") {
      toast.error("Name can't be empty");
      return;
    }
    if (selectedJob.name == null) {
      toast.error("You must select a job");
    }
    const res = await updateService({
      id: serviceId,
      name: form.name,
      isEfileStatusActive: form.isEfileStatusActive,
      isEveryItemRenewable: form.isEveryItemRenewable,
      departmentId: selectedDepartment.id,
      jobId: selectedJob.id,
    });

    if (res.data.success) {
      setService({ name: form.name });
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  };

  const onHandleChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onHandleChangeEfileStatus = (e) => {
    setForm({ ...form, isEfileStatusActive: e.target.checked });
  };

  const onHandleChangeIsEveryItemRenewable = (e) => {
    setForm({ ...form, isEveryItemRenewable: e.target.checked });
  };

  const onHandleChangeDepartment = (e) => {
    setSelectedDepartment(e);
  };

  /*  const onHandleChangeJob = (e) => {
    setSelectedJob(e);
    getNewTasks(e.id);
  }; */

  return (
    <section>
      <Toaster />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="flex flex-col items-start max-w-2xl">
                  <Breadcrumbs
                    home="/departments"
                    pages={[
                      {
                        name: "Service",
                        href: `/services/${serviceId}`,
                        current: true,
                      },
                    ]}
                  />

                  {/* Page header */}
                  <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                    {/* Left: Title */}
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Edit service
                        <span className="text-slate-400 font-normal">
                          {" "}
                          {service.name}{" "}
                        </span>
                      </h1>
                    </div>
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                    <header className="px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">Details</h2>
                    </header>

                    <div className="space-y-5 px-5 py-4">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Service Template Name
                        </label>
                        <div className="mt-1">
                          <input
                            name="name"
                            type="text"
                            value={form.name}
                            onChange={(e) => onHandleChangeInput(e)}
                            className="inline-flex truncate justify-between w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-base font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <DepartmentListbox
                          label={"Department"}
                          departments={departments}
                          selectedDepartment={selectedDepartment}
                          onChangeDepartment={(e) => onHandleChangeDepartment(e)}
                        />
                        <div className="mt-4">
                          <label
                            htmlFor="jobs"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Job Template
                          </label>
                          <input
                            disabled
                            className="inline-flex truncate justify-between w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-base font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                            value={selectedJob.name}
                          />
                        </div>
                      </div>

                      {/*
                        <JobsListBox
                          label={"Jobs"}
                          items={jobs}
                          selectedItem={selectedJob}
                          onChangeItem={(e) => onHandleChangeJob(e)}
                        />
                      </div> */}
                      <CheckboxForServices
                        name="Add E-File Status"
                        value={form.isEfileStatusActive}
                        message="Check this box to display E-File Status in subscriptions."
                        checked={form.isEfileStatusActive}
                        onChange={(e) => onHandleChangeEfileStatus(e)}
                      />

                      <CheckboxForServices
                        name="Make jobs renewable automatically"
                        value={form.isEveryItemRenewable}
                        message="Check this box to renew the service automatically."
                        checked={form.isEveryItemRenewable}
                        onChange={(e) => onHandleChangeIsEveryItemRenewable(e)}
                      />
                    </div>
                  </div>

                  <div className="w-full flex justify-end">
                    <button
                      onClick={() => saveDetails()}
                      type="submit"
                      className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                    >
                      Save
                    </button>
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
                    <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">
                        Jobs assigned to current service
                      </h2>
                    </header>
                    {tasks.map((task) => (
                      <div className="m-2 text-sm w-full pl-4 py-2">
                        <div className="flex w-full items-center">
                          <h1
                            htmlFor="comments"
                            className="w-full font-medium text-gray-700"
                          >
                            {task.name}
                          </h1>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*  <EditServiceTaskSection
                    title={FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASKS}
                    serviceId={serviceId}
                  /> */}
                </div>
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default EditServiceForm;
