import { useState } from "react";
import { loginToAccount } from "../../services/login";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Login() {

    const navigate = useNavigate();

    const [form, setForm] = useState({
        email: '',
        password: '',
    })

    async function login() {
        const res = await loginToAccount(form);
        console.log(res);
        if (res.success) {
            var expirationDate = new Date(res.data.expirationDateTime);
            Cookies.set('Bearer', `Bearer ${res.data.token}`, {expires: expirationDate});
           navigate('/tasks');
        } else {
            alert(res.message);
        }
    }

    useEffect(() => {
        if (typeof Cookies.get('Bearer') !== 'undefined') {
            navigate('/');
        }
        // eslint-disable-next-line
    }, [])

    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
                        <div className="px-4">
                            <div className="flex items-center justify-between h-16 ">
                            <img src="./logo_white.png" alt="logo" className="h-10" />
                            </div>
                        </div>
                    </header>

                    <main>
                        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                            <div className="max-w-md w-full space-y-8">
                                <div>
                                    <div className="flex justify-center w-full">
                                        <img src="./logo_white.png" alt="logo" className="w-100 h-40" />
                                    </div>
                                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>
                                    <p className="mt-2 text-center text-sm text-gray-600">
                                        Or{' '}
                                        <button onClick={() => navigate('/register')} className="font-medium text-teal-500 hover:text-teal-400 cursor-pointer">
                                            create an account
                                        </button>
                                    </p>
                                </div>
                                <div className="mt-8 space-y-6">
                                    <input type="hidden" name="remember" defaultValue="true" />
                                    <div className="rounded-md shadow-sm -space-y-px">
                                        <div>
                                            <label htmlFor="email-address" className="sr-only">
                                                Email address
                                            </label>
                                            <input
                                                onChange={(e) => setForm({ ...form, email: e.target.value })}
                                                id="email-address"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                                                placeholder="Email address"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="sr-only">
                                                Password
                                            </label>
                                            <input
                                                onKeyDown={(e) => {
                                                    if (e.code === 'Enter') {
                                                        login();
                                                    }
                                                }}
                                                onChange={(e) => setForm({ ...form, password: e.target.value })}
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        {/* <div className="flex items-center">
                                            <input
                                                id="remember-me"
                                                name="remember-me"
                                                type="checkbox"
                                                className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                Remember me
                                            </label>
                                        </div> */}

                                        <div className="text-sm">
                                        <a
                                        className="font-medium text-blue-500 hover:text-blue-400"
                                        onClick={() => 
                                            navigate("/forgotPassword")
                                         }>
                                            Forgot your password?
                                        </a>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            onClick={() => login()}
                                            onKeyDown={e => {
                                                if(e.code === 'Enter'){
                                                    login();
                                                }
                                            }}
                                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                        >
                                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-teal-500 group-hover:text-teal-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                                </svg>
                                            </span>
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    )

}