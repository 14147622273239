import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ChecklistItemsList from "../../components/clientServiceSubscription/tasks/checklistItems/ChecklistItemsList";
import EditItemModal from "../Jobs/EditItemModal";
import { useState } from "react";
import { ArrowsUpDownIcon } from "@heroicons/react/24/solid";

const ChecklistFormSection = ({
    taskId,
    checklist,
    inputCheckList,
    retrieveChecklist,
    setCheckList,
    setInputCheckList,
    updateChecklistItem,
    removeChecklistItem,
    createChecklistItem,
}) => {
    const [openEditItem, setOpenEditItem] = useState(false);
    const [checklistToEdit, setChecklistToEdit] = useState([]);

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = async (result) => {
        // dropped outside the list
        console.log(checklist);
        if (!result.destination) {
            return;
        }

        const items = reorder(
            checklist,
            result.source.index,
            result.destination.index
        );
        setCheckList(items);
        await Promise.all(
            items.map(async (item, index) => {
                await updateChecklistItem({
                    id: item.id,
                    task_id: taskId,
                    description: item.description,
                    links: JSON.stringify(item.links),
                    order: index,
                });
            })
        );
    };

    const onClickEditItem = (value, index) => {
        const item = {
            ...value,
            order: index,
        };
        setChecklistToEdit(item);
        setOpenEditItem(true);
    };

    return (
        <div className="space-y-5 px-5 py-4">
            <EditItemModal
                open={openEditItem}
                setOpen={setOpenEditItem}
                checklistItemToEdit={checklistToEdit}
                setChecklistItemToEdit={setChecklistToEdit}
                retrieveChecklist={retrieveChecklist}
            />
            {/* <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {checklist.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.description}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {checklist.map((value, index) => {
                                return (
                                    <Draggable
                                        key={value.id}
                                        draggableId={value.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div
                                                    key={index}
                                                    className="relative flex items-center"
                                                >
                                                    <ArrowsUpDownIcon className="h-4 w-4" />
                                                    <div className="ml-2 text-sm w-full">
                                                        {value.editing ? (
                                                            <div className="flex w-full">
                                                                <input
                                                                    type="text"
                                                                    value={
                                                                        value.description
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        const arr = [
                                                                            ...checklist,
                                                                        ];
                                                                        arr[
                                                                            index
                                                                        ].description =
                                                                            e.target.value;
                                                                        arr[
                                                                            index
                                                                        ].updated = true;
                                                                        setCheckList(
                                                                            arr
                                                                        );
                                                                    }}
                                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    id="update-btn"
                                                                    onClick={async () => {
                                                                        if (
                                                                            value.updated
                                                                        ) {
                                                                            await updateChecklistItem(
                                                                                {
                                                                                    id: value.id,
                                                                                    task_id:
                                                                                        taskId,
                                                                                    description:
                                                                                        value.description,
                                                                                    order: index,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            const arr =
                                                                                [
                                                                                    ...checklist,
                                                                                ];
                                                                            console.log(
                                                                                arr
                                                                            );
                                                                        }
                                                                        retrieveChecklist();
                                                                    }}
                                                                    className="ml-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-5 w-5"
                                                                        viewBox="0 0 20 20"
                                                                        fill="currentColor"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    id="cancel-edit-btn"
                                                                    onClick={() => {
                                                                        retrieveChecklist();
                                                                    }}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div className="flex w-full items-center">
                                                                <h1
                                                                    htmlFor="comments"
                                                                    className="w-full font-medium text-gray-700"
                                                                >
                                                                    {
                                                                        value.description
                                                                    }
                                                                </h1>
                                                                <div className="w-full">
                                                                    <ChecklistItemsList
                                                                        links={
                                                                            value.links
                                                                                ? value.links
                                                                                : []
                                                                        }
                                                                    />
                                                                </div>

                                                                <button
                                                                    id="edit-btn"
                                                                    onClick={() =>
                                                                        onClickEditItem(
                                                                            value,
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    id="delete-btn"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        removeChecklistItem(
                                                                            value.id
                                                                        );
                                                                    }}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        fill="currentColor"
                                                                        className="w-7 h-7 hover:text-red-500 p-1"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {inputCheckList.map((value, i) => {
                return (
                    <div className="relative flex items-start">
                        <input
                            type="text"
                            onChange={(e) => {
                                const arr = [...inputCheckList];
                                arr[i].description = e.target.value;
                                setInputCheckList(inputCheckList);
                            }}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                        />
                        <button
                            onClick={async () => {
                                if (value.description === "") return;
                                let newIndex = checklist.length;
                                console.log(`index ${i} length ${newIndex}`);
                                const res = await createChecklistItem({
                                    task_id: taskId,
                                    description: value.description,
                                    order: newIndex,
                                });

                                if (res.data.success) {
                                    retrieveChecklist();
                                    const arr = [...inputCheckList];
                                    arr.splice(i, 1);
                                    setInputCheckList(arr);
                                } else {
                                    alert(res.data.message);
                                }
                            }}
                            type="button"
                            className="ml-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            id="cancel-btn"
                            onClick={() => {
                                const arr = [...inputCheckList];
                                arr.splice(i, 1);
                                setInputCheckList(arr);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                />
                            </svg>
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default ChecklistFormSection;
