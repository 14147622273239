/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { getTimestamp } from "../../../helpers/getTimestamp";
import { updateTaskService } from "../../../services/clientServiceSubscriptionTask/updateTaskService";
import { updateClientServiceSubscriptionTaskChecklistItemCheck } from "../../../services/clientServiceSubscriptionTaskChecklistItem/updateClientServiceSubscriptionTaskChecklistItemCheck";
import { getEmployees } from "../../../services/getEmployees";
import ButtonAddTimestamp from "../../../utils/ButtonAddTimestamp";
import AssignedEmployeesListBox from "./AssignedEmployeesListBox";
import ButtonModal from "./ButtonModal";
import ErrorMessage from "./ErrorMessage";
import InputDateModal from "./InputDateModal";
import InputTextAreaModal from "./InputTextAreaModal";

const AssignNewEmployeeToJobModal = ({
  open,
  setOpen,
  currentJob,
  setCurrentJob,
  nextJob,
  setNextjob,
  getNewTasks,
  checklistItemToUpdate,
  clientService,
  renewTask,
  loggedInEmployee,
}) => {
  const [employees, setEmployees] = useState([]);
  const [errors, setErrors] = useState([]);

  let completeButtonRef = useRef(null);

  useEffect(() => {
    console.log("nextJob", nextJob);
  }, []);

  const onHandleCancel = () => {
    setErrors([]);
    setOpen(false);
  };

  const onHandleChangeAssignedEmployees = (newAssignedEmployees) => {
    console.log(nextJob);
    setNextjob({
      ...nextJob,
      assignedEmployees: newAssignedEmployees,
      assignedDate: moment().format("YYYY-MM-DD"),
    });
  };

  const onHandleChange = (e) => {
    console.log(e.target.name, e.target.value);
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setNextjob({ ...nextJob, [fieldName]: fieldValue });
  };

  const onHandleClickAddTimestamp = (fieldName) => {
    setNextjob({
      ...nextJob,
      [fieldName]:
        (nextJob[fieldName] !== null ? nextJob[fieldName] : "") +
        getTimestamp(nextJob.notes, loggedInEmployee),
    });
  };

  const validateForm = (form) => {
    if (form.assignedEmployees.length < 1) {
      setErrors({ assignedEmployees: "This field cannot be empty." });
      return false;
    }
    return true;
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(nextJob)) {
      return;
    }
    const updateNextJobResponse = await updateTaskService(nextJob.id, nextJob);
    if (updateNextJobResponse.data.success) {
      const updateChecklistItemResponse =
        await updateClientServiceSubscriptionTaskChecklistItemCheck(
          checklistItemToUpdate.id,
          true
        );
      if (updateChecklistItemResponse.success) {
        const updateCurrentJobResponse = await updateTaskService(
          currentJob.id,
          currentJob
        );
        if (updateCurrentJobResponse.data.success) {
          toast.success("Jobs updated successfully!");
          /*
          Renew jobs individually disabled
           if (clientService.isEveryItemRenewable) {
            renewTask(clientService.id, currentJob.id, currentJob.assignedDate);
          }
          */

          getNewTasks();
        } else {
          toast.error(updateCurrentJobResponse.data.message);
        }
      } else {
        toast.error(updateChecklistItemResponse.data.message);
      }
    } else {
      toast.error(updateNextJobResponse.data.message);
    }
    setCurrentJob({});
    setNextjob({});
    setOpen(false);
  };

  const getNewEmployees = async () => {
    const response = await getEmployees();
    setEmployees(response.data.employees);
  };

  useEffect(() => {
    getNewEmployees();
  }, []);

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          initialFocus={completeButtonRef}
          static
          as={motion.div}
          open={open}
          onClose={() => setOpen(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                  Assign Employees to Job - {nextJob.name}
                </Dialog.Title>

                <form onSubmit={onHandleSubmit}>
                  <AssignedEmployeesListBox
                    title="Employees *"
                    employees={employees}
                    assignedEmployees={nextJob.assignedEmployees}
                    onChange={onHandleChangeAssignedEmployees}
                  />
                  {<ErrorMessage error={errors["assignedEmployees"]} />}

                  <InputTextAreaModal
                    label="Notes"
                    name="notes"
                    onChange={onHandleChange}
                    value={nextJob.notes}
                  />
                  <ButtonAddTimestamp
                    onHandleClick={() => onHandleClickAddTimestamp("notes")}
                  />

                  <InputDateModal
                    label={"Due Date"}
                    name="dueDate"
                    onChange={onHandleChange}
                    value={nextJob.dueDate && nextJob.dueDate.split("T")[0]}
                  />
                  <ErrorMessage error={errors["dueDate"]} />
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <ButtonModal
                      name="Save"
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                    />
                    <ButtonModal
                      name="Cancel"
                      type="button"
                      onClick={onHandleCancel}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                    />
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
export default AssignNewEmployeeToJobModal;
