import { useMemo } from "react";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import NewBadge from "../../components/NewBadge";

const MyAssignedChecklistItemsTable = ({ employeeName, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Client",
                accessor: "client",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.row.original.isNew === true ? (
                            <span>
                                <NewBadge label="New" /> {props.value}
                            </span>
                        ) : (
                            props.value
                        )}
                    </a>
                ),
            },
            {
                Header: "Service",
                accessor: "service",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Job",
                accessor: "job",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Checklist Item",
                accessor: "checklistItem",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Department",
                accessor: "department",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Assigned Date",
                accessor: (row) =>
                    new Date(row.assignedDate).toDateString().substring(4),
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Due Date",
                accessor: (row) => new Date(row.dueDate).toDateString().substring(4),
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
        ],
        [employeeName]
    );
    return (
        <div>
            {data != null ? (
                <FirmFlowTable
                    columns={columns}
                    data={data}
                    employee={employeeName}
                />
            ) : (
                "No data found"
            )}
        </div>
    );
};

export default MyAssignedChecklistItemsTable;
